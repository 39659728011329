import React, { useEffect} from 'react';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg'
import { getCalApi } from "@calcom/embed-react";


export const Header = () => {

  const handleGoHome = () => {
    window.location.href = "/";
  };

  useEffect(()=>{
	  (async function () {
		const cal = await getCalApi({"namespace":"15min"});
		cal("ui", {"theme":"light","styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":false,"layout":"month_view"});
	  })();
	}, [])

  return (
    <header className="sticky top-0 w-full bg-white/90 backdrop-blur-sm z-50 py-4">
      <div className="container mx-auto px-4 flex items-center">
        <div className="flex items-center gap-2 flex-1">
          <LogoIcon className="h-[50px] hover:cursor-pointer"  onClick={handleGoHome} />
          <h1 className="text-3xl font-semibold hover:cursor-pointer"  onClick={handleGoHome}>Whiteboard</h1>
        </div>

        <button 
          className="text-black font-bold text-lg hover:border-b-black hover:border-b-2 mr-10"
          data-cal-namespace="15min" 
          data-cal-link="sahil-chatiwala/15min" 
          data-cal-config='{"layout":"month_view","theme":"light"}'
        >
          Schedule Demo
        </button>

        <a 
          href="https://tally.so/r/w5qEbN" 
          target="_blank" 
          rel="noopener noreferrer"
        >
            <button
              className="text-white font-bold border-1 text-lg border-black rounded-xl shadow-lg px-6 py-4 bg-blue-500 hover:bg-[#77B3F8]"
            >
              Join Waitlist
            </button>
        </a>
      </div>
    </header>
  );
};