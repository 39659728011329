// src/components/sections/IntegrationsSection.jsx
import React from 'react';
import Calendar from '../ui/Calendar';

export const CalendarSection = () => {

  return (
  <section>
    <div className="w-full max-h-[80%] flex flex-col items-center justify-center overflow-hidden bg-white">
      <div className="h-full flex flex-col justify-center mt-10">
        <div>
          <div className="inline-flex items-center bg-gray-50 pl-1 py-1 rounded-md mb-10 whitespace-nowrap">
            <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
            <div className="text-blue-500 text-sm font-medium font-mono px-1">CONNECT</div>
            <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
          </div>
        </div>
      </div>
      <h1 className="text-5xl font-bold mb-4">
        <span className="text-blue-500">Interested?</span> Let's connect.
      </h1>
      <p className="w-auto mx-auto mt-1 mb-10">
        We're excited to learn about your business needs and how Whiteboard can help!
      </p>
      <div className="w-full bg-white">
        <Calendar/>
      </div>
    </div>

  </section>
  );
};