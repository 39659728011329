import React from "react";

export const CompanyCard = ({ icon, name }) => (
  <div className="h-[175px] w-[175px] border-2 border-gray-100 bg-gray-50 rounded-2xl shadow-md hover:shadow-xl transition-shadow gap-[50px] flex flex-col items-start justify-between">
    <div className="h-[30%] w-[30%] flex items-start justify-start mt-5 ml-5">
      {React.isValidElement(icon) ? (
        React.cloneElement(icon, { className: 'h-full w-full' }) // ensures SVGs take full size
      ) : (
        <span className="h-[40%] w-[40%] flex items-start justify-start pl-2">{icon}</span> // handles emoji icons
      )}
    </div>
    <div className="w-full ml-5 mb-5">
      <span className="text-lg font-bold">{name}</span>
    </div>
  </div>
);
