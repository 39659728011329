import React from 'react';
import { CompanyCard } from '../ui/CompanyCard';
import { ReactComponent as SlackIcon } from '../../assets/slack_logo.svg'
import { ReactComponent as TeamsIcon } from '../../assets/teams_logo.svg'
import { ReactComponent as MailIcon } from '../../assets/mail_logo.svg'
import { ReactComponent as MessageIcon } from '../../assets/message_logo.svg'

export const IntegrationsSection = () => {
  const integrations = [
    { name: 'Slack', icon: <SlackIcon/> },
    { name: 'Teams', icon: <TeamsIcon /> },
    { name: 'Email', icon: <MailIcon /> },
    { name: 'Live Chat', icon: <MessageIcon />},
  ];

  return (
    <section>
      <div className="w-full max-h-[80%] flex flex-col items-center justify-center overflow-hidden bg-white">
        <div className="w-full max-w-[50%] h-full flex flex-col justify-center mt-10 px-4">
          <div className="flex items-center justify-center">
            <div className="inline-flex items-center bg-gray-50 px-1 py-1 rounded-md mb-10 whitespace-nowrap">
              <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
              <div className="text-blue-500 text-sm font-medium font-mono px-1">INTEGRATIONS</div>
              <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
            </div>
          </div>
        </div>
        <h1 className="text-5xl font-bold mb-4 text-center">
          <span className="text-blue-500">Everything</span> in one place
        </h1>
        <p className="text-gray-700 w-auto max-w-[75%] xl:max-w-[60%] text-center lg:text-center px-4 mt-1">
          With seamless integrations for the platforms you and your customers use, 
          Whiteboard lets you meet customers where they are.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-20 my-20 px-4">
          {integrations.map((integration) => (
            <CompanyCard key={integration.name} {...integration} />
          ))}
        </div>
      </div>
    </section>
  );
};