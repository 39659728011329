import Cal, { getCalApi } from "@calcom/embed-react";
  import { useEffect } from "react";
  export default function Calendar() {
	useEffect(()=>{
	  (async function () {
		const cal = await getCalApi({"namespace":"15min"});
		cal("ui", {"theme":"light","styles":{"branding":{"brandColor":"#000000"}},"hideEventTypeDetails":false,"layout":"month_view"});
	  })();
	}, [])
	return <Cal namespace="15min"
	  calLink="sahil-chatiwala/15min"
	  style={{width:"100%",height:"100%",overflow:"scroll", backgroundColor:"#fff"}}
	  config={{"layout":"month_view","theme":"light"}}
	/>;
  };
  