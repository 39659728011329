import React from 'react';
import kanbanImage from '../../assets/kanban_full.jpeg';

export const HeroSection = () => {
  return (
    <section>
      <div className="w-full max-h-[70%] flex justify-center overflow-hidden bg-gray-100">
        <div className="w-full xl:max-w-[60%] flex flex-col xl:flex-row xl:gap-[100px] items-center my-20 px-4 xl:px-0">
          
          {/* Left Column - Hero Content */}
          <div className="w-full xl:max-w-[60%] h-full flex flex-col items-center xl:items-start text-center xl:text-left mb-12 xl:mb-0">
            <div className="w-[100%] flex xs: justify-center xl:justify-start">
              <div className="inline-flex items-center bg-gray-50 p-1 py-1 rounded-md mb-10 whitespace-nowrap">
                <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
                <div className="text-blue-500 text-sm font-medium font-mono px-1">FEATURES</div>
                <div className="w-[6px] h-[6px] bg-blue-500 rounded-full mx-1" />
              </div>
            </div>

            <h1 className="text-7xl font-bold mb-6 leading-tight">
              <span className="text-blue-500">Supercharged</span>{" "}
              <span className="whitespace-nowrap">B2B customer</span> support
            </h1>

            <p className="text-gray-700 text-xl md:text-xl mb-8 leading-relaxed">
              Accelerated issue identification, tracking, and 
              resolution for the busiest teams.
            </p>

            <div>
              <a 
                href="https://tally.so/r/w5qEbN" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <button className="w-full hover:bg-gray-200 bg-white transition duration-300 text-black font-bold border-1 text-xl border-black rounded-xl shadow-xl px-6 py-4">
                  Join Waitlist
                </button>
              </a>
            </div>
          </div>

          {/* Right Column - Image Placeholder */}
          <div className="w-full xl:w-auto flex items-center justify-center overflow-visible px-4 xl:px-0">
            <img 
              src={kanbanImage} 
              alt="Kanban Board" 
              className="w-full xl:w-auto rounded-2xl border-8 border-gray-50 shadow-xl max-w-none object-cover max-h-[600px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
};