// src/components/layout/Footer.jsx
import React from 'react';

export const Footer = () => {
  return (
  <footer className="bg-gray-50 py-8">
    <div className="container mx-auto px-4 flex flex-row justify-center items-center">
      <p className="text-gray-600">© 2024 Whiteboard. All rights reserved.</p>
    </div>
  </footer>
  );
};